import LayoutProductPage from '../components/layouts/ProductPage';
import * as styleProduct from '../styles/pages/productIndex.module.scss';
import * as styleOperationPage from '../styles/pages/operationPage.module.scss';
import * as gstyle from '../styles/styles.module.scss';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';
import React from 'react';
import classnames from 'classnames';
import { FaqPagedata } from '../data/page-faq.data';
import { Accordion } from '../data/accordion.data';

function faqPage() {
  const data = FaqPagedata();
  let page_content = data?.content;
  const dateStr = data.modified;
  const dateObj = new Date(dateStr);
  const friendlyDate = dateObj.toLocaleString(undefined, {
    dateStyle: 'short',
    timeStyle: 'short',
  });
  page_content = new Accordion(page_content).get();
  return (
    <section>
      <Helmet title="TALXIS Frequently Asked Questions"> </Helmet>
      <LayoutProductPage
        header="TALXIS"
        copy=""
        subHeader="Frequently Asked Questions"
        color="purple"
        backLink="/products"
      >
        <section className={styleOperationPage.section} style={styleOperationPage}>
          <div className={classnames(gstyle.center, gstyle.modified)}>
            Last modification made on {friendlyDate}
          </div>

          <div
            className={classnames(gstyle.center, styleOperationPage.content)}
            dangerouslySetInnerHTML={{ __html: page_content }}
          ></div>
        </section>
      </LayoutProductPage>
      <section className={styleProduct.footerSection}>
        <Footer></Footer>
      </section>
    </section>
  );
}
export default faqPage;
