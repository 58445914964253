import { I18nextContext } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { useFaqPageMetaDataCs } from '../hooks/cs/page-faq.metadata';
import { useFaqPageMetaDataEn } from '../hooks/en/page-faq.metadata';

export function FaqPagedata(): any {
  const { language } = React.useContext(I18nextContext);
  let data;
  if (language == 'cs') {
    data = useFaqPageMetaDataCs();
  } else {
    data = useFaqPageMetaDataEn();
  }
  return data;
}
